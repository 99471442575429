<template>
  <div class="ColumnConfig">
    <el-row :gutter="0">
      <!-- 左侧树形条 start @author: HDZ !-->
      <el-col :xl="5" :lg="7" :md="9" :sm="9">
        <tree-comp @nodeClick="nodeClick" ref="treeComp"></tree-comp>
      </el-col>
      <!-- 右侧内容 start @author: HDZ !-->
      <el-col :xl="19" :lg="17" :md="15" :sm="15">
        <main-content
          :data-all="dataAll"
          :loading="isLoading"
          class="main-content"
        ></main-content>
      </el-col>
    </el-row>
  </div>
</template>

<script>

const TreeComp = () => import("@components/content/treecomp/TreeComp")
const MainContent = () => import("./childcomponent/MainContent.vue");
export default {
  name: "ColumnConfig",
  data() {
    return {
      dataAll: {}, //保存节点的数据
      isLoading: false,
    };
  },
  components: {
    TreeComp,
    MainContent,
  },
  methods: {
    // 节点点击
    nodeClick(data) { //nodeClick为TreeComp组件自定义事件传递过来的
      console.log(data); //data为 TreeComp组件传递过来的数据
      if (!this.isLoading) {
        this.isLoading = true;
      }
      setTimeout(() => {
        this.dataAll = data;
        if(this.dataAll.images){
          // this.dataAll.images = JSON.parse(this.dataAll.images) || []
          this.dataAll.images = typeof(this.dataAll.images) == "string" ? JSON.parse(this.dataAll.images) : this.dataAll.images
        }

        this.isLoading = false;
        
      }, 1000);
    },
  },
  mounted() {
    this.dataAll = {
      // id: "demo1",
      // label: "默认显示",
      // level: 2,
    };
    // this.$bus.$on("saveSuccess", (id) => {
    //   this.$nextTick( ()=> {
    //     this.$refs.treeComp.queryColumnTree(id);
    //     this.$refs.treeComp.defaultExpandKey.push(id);
    //     //document.querySelector(".is-current").firstChild.click();
    //     console.log("save");
    //   });
    // });
  },
};
</script>

<style lang='scss' scoped>
.main-content {
  min-width: 700px;
}
/deep/.el-tabs--border-card > .el-tabs__content{
  padding: 0;
}

</style>